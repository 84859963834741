<template>
  <div class="container-fluid">
    <div class="page-title">
      <i class="fa-regular fa-address-card"></i>
      <h1>{{ $t("COMMON.CONTACTS") }}</h1>
    </div>
    <div class="page-wrapper">
      <div slot="header" class="page-wrapper-header">
        <div class="row">
          <div class="col-6">
            <h3 class="mb-0">{{ $t("CONTACTS.CONTACTS_LIST") }}</h3>
          </div>
          <div class="col-6 text-right">
            <base-button
              class="elite-button add"
              icon
              size="sm"
              @click="openContactCreateModal"
              v-if="$currentUserCan($permissions.PERM_CREATE_CONTACTS)"
            >
              <span class="btn-inner--icon">
                <i class="far fa-plus-circle"></i>
              </span>
              <span class="btn-inner--text">{{
                $t("CONTACTS.ADD_CONTACT")
              }}</span>
            </base-button>
            <!--<notification-subscription
              v-if="$currentUserCan($permissions.PERM_VIEW_CONTACTS)"
              :objectType="'contacts'"
              :events="{
                CREATE: $t('NOTIFICATIONS.EVENT_CREATE'),
                UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
              }"
            />-->
          </div>
        </div>
      </div>

      <contact-list-table
        @onViewContact="openContactViewModal"
        @onEditContact="openContactEditModal"
        @onDeleteContact="deleteContact"
        :key="renderKey * 100"
      />

      <div
        v-if="isViewContactModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isViewContactModalOpened ? 'show' : 'hide']"
        :key="renderKey * 200"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'CONTACT'"
          @onCloseModal="closeContactModal(true, true)"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("CONTACTS.VIEW_CONTACT") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <notification-subscription
                v-if="openContact"
                :objectType="'contacts'"
                :objectId="openContact.id"
                :events="{
                  UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                  DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
                }"
              />
              <base-dropdown
                title-classes="btn dropdown-button"
                menu-on-right
                :has-toggle="false"
              >
                <template slot="title">
                  <i class="fas fa-ellipsis-v"></i>
                </template>
                <button class="edit" @click="openContactEditModal(openContact)">
                  <i class="fal fa-edit"></i>
                  <span>{{ $t("COMMON.EDIT") }}</span>
                </button>
                <button class="delete" @click="deleteContact(openContact)">
                  <i class="fal fa-trash-alt"></i>
                  <span>{{ $t("COMMON.DELETE") }}</span>
                </button>
              </base-dropdown>
              <button class="close" @click="closeContactModal(true)">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <view-contact-component
              v-if="openContact"
              :contactId="openContact.id"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isEditContactModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isEditContactModalOpened ? 'show' : 'hide']"
        :key="renderKey * 300"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'CONTACT'"
          @onCloseModal="closeContactModal"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("CONTACTS.EDIT_CONTACT") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <button class="close" @click="openContactViewModal(openContact)">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <edit-contact-component
              v-if="openContact"
              :contactId="openContact.id"
              @onViewContact="openContactViewModal"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isAddContactModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isAddContactModalOpened ? 'show' : 'hide']"
        :key="renderKey * 400"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'CONTACT'"
          @onCloseModal="closeContactModal"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("CONTACTS.ADD_CONTACT") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <button class="close" @click="closeContactModal">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <add-contact-component @onViewContact="openContactViewModal" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import swal from "sweetalert2";
import { Button } from "element-ui";
import requestErrorMixin from "@/mixins/request-error-mixin";
import {
  QUERY_ACTIONS_VIEW,
  QUERY_ACTIONS_EDIT,
  QUERY_ACTIONS_ADD,
} from "@/constants/common";
import NotificationSubscription from "@/components/NotificationSubscription.vue";
import ContactListTable from "./partials/ContactListTable.vue";
import EditContactComponent from "./components/EditContactComponent.vue";
import AddContactComponent from "./components/AddContactComponent.vue";
import ViewContactComponent from "./components/ViewContactComponent.vue";

export default {
  layout: "DashboardLayout",

  components: {
    ContactListTable,
    NotificationSubscription,
    EditContactComponent,
    AddContactComponent,
    ViewContactComponent,
    [Button.name]: Button,
  },

  mixins: [requestErrorMixin],

  computed: {},

  watch: {},

  data() {
    const contactId = this.$route.query.id;
    const action = this.$route.query.action;
    let isViewContactModalOpened = false;
    let isEditContactModalOpened = false;
    let isAddContactModalOpened = false;
    let openContact = null;
    if (contactId && action) {
      if (action === QUERY_ACTIONS_VIEW) {
        isViewContactModalOpened = true;
      } else if (action === QUERY_ACTIONS_EDIT) {
        isEditContactModalOpened = true;
      }
      openContact = { id: contactId };
    } else if (action === QUERY_ACTIONS_ADD) {
      isAddContactModalOpened = true;
    }
    return {
      isViewContactModalOpened: isViewContactModalOpened,
      isEditContactModalOpened: isEditContactModalOpened,
      isAddContactModalOpened: isAddContactModalOpened,
      openContact: openContact,
      renderKey: 1,
    };
  },

  methods: {
    openContactCreateModal() {
      this.closeContactModal();
      this.isAddContactModalOpened = true;

      this.$router.replace({
        name: "List Contacts",
        query: { action: QUERY_ACTIONS_ADD },
      });
    },
    openContactViewModal(contact, reRender = false) {
      this.closeContactModal();
      this.openContact = contact;
      this.isViewContactModalOpened = true;
      if (reRender) {
        this.renderKey++;
      }

      this.$router.replace({
        name: "List Contacts",
        query: { id: this.openContact.id, action: QUERY_ACTIONS_VIEW },
      });
    },
    openContactEditModal(contact) {
      this.closeContactModal();
      this.openContact = contact;
      this.isEditContactModalOpened = true;

      this.$router.replace({
        name: "List Contacts",
        query: { id: this.openContact.id, action: QUERY_ACTIONS_EDIT },
      });
    },
    closeContactModal(goBack = false, grid = false) {
      if (goBack && this.$store.getters["routes/previousPathRoute"]) {
        if (grid) {
          this.$store.dispatch("routes/goBackToPreviousGrid");
        } else {
          this.$store.dispatch("routes/goToPreviousPath");
        }
        return;
      }
      this.isAddContactModalOpened = false;
      this.isViewContactModalOpened = false;
      this.isEditContactModalOpened = false;
      this.openContact = null;

      if (
        this.$route.name !== "List Contacts" ||
        JSON.stringify(this.$route.query) !== JSON.stringify({})
      ) {
        this.$router
          .replace({
            name: "List Contacts",
            query: {},
          })
          .catch((err) => {
            if (err.name !== "NavigationDuplicated") {
              throw err;
            }
          });
      }
    },

    async deleteContact(contact) {
      const confirmation = await swal.fire({
        title: this.$t("CONTACTS.DELETE_THIS_CONTACT"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.isConfirmed) {
          await this.$store.dispatch("contacts/destroy", contact.id);
          this.renderKey++;
          this.closeContactModal();
          this.$notify({
            type: "success",
            timeout: 3000,
            message: this.$t("CONTACTS.CONTACT_DELETED"),
          });
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
  },
};
</script>
