<template>
  <div class="container-fluid">
    <contact-form
      :loading="loading"
      :contactData="contact"
      :formErrors="formErrors"
      @contactSubmitted="handleSubmit"
      @formChanged="() => (alertLeave = true)"
    />
  </div>
</template>

<script>
import { Select, Option } from "element-ui";
import { cloneDeep } from "lodash";
import alertLeave from "@/mixins/alert-leave-mixin";
import defaultContact from "../defaultContact";
import ContactForm from "../partials/ContactForm.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    ContactForm,
  },

  mixins: [alertLeave],

  data() {
    return {
      contact: cloneDeep(defaultContact),
      formErrors: null,
      loading: false,
    };
  },

  created() {},

  methods: {
    async handleSubmit(contact) {
      this.loading = true;

      const contactData = cloneDeep(contact);
      delete contactData.id;

      try {
        await this.$store.dispatch("contacts/add", contactData);
        this.$notify({
          type: "success",
          timeout: 3000,
          message: this.$t("CONTACTS.CONTACT_ADDED"),
        });
        const contact = await this.$store.getters["contacts/contact"];
        this.$emit("onViewContact", contact, true);
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },
  },
};
</script>
